<template>
  <Card title="编辑角色">
    <a-tabs v-model="selectedKey">
      <a-tab-pane key="1" tab="基本信息">
        <a-form
          :form="form"
          @submit="handleSubmit"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :colon="false"
        >
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="编码">
                <a-input
                  v-decorator="[
                    'code',
                    {
                      initialValue: detail.code,
                      rules: [
                        {
                          required: true,
                          message: '请输入',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="名称">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      initialValue: detail.name,
                      rules: [
                        {
                          required: true,
                          message: '请输入',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="描述" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-textarea
                  :auto-size="{ minRows: 3 }"
                  v-decorator="[
                    'remarks',
                    {
                      initialValue: detail.remarks,
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <div class="center">
            <a-space>
              <a-button @click="cancel">关闭</a-button>
              <a-button htmlType="submit" type="primary">下一步</a-button>
            </a-space>
          </div>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="功能权限">
        <a-checkbox-group v-model="selectedList">
          <div v-for="(element, index) in permissions" :key="index">
            <h3 class="group-name">{{ element.name }}</h3>

            <div class="permission-list">
              <a-row :gutter="[8, 8]">
                <a-col
                  :span="4"
                  class="text-hidden"
                  v-for="permission in element.list"
                  :key="permission.id"
                >
                  <a-checkbox :value="permission.id">
                    {{
                    permission.name
                    }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-checkbox-group>

        <div class="center">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button type="primary" @click="save">保存</a-button>
          </a-space>
        </div>
      </a-tab-pane>
    </a-tabs>
  </Card>
</template>

<script>
import { edit } from "@/api/setting/role";
import { fetchList as fetchPermissionList } from "@/api/setting/permission";
import { fetchDetail } from "@/api/setting/role";
export default {
  data() {
    return {
      selectedKey: "1",
      form: this.$form.createForm(this),
      permissionList: [],
      selectedList: [],
      detail: {},
    };
  },

  computed: {
    permissions() {
      const groupNames = this.permissionList
        .map((item) => item.groupName)
        .filter((item, index, self) => self.indexOf(item) === index);
      return groupNames.map((name) => {
        return {
          name,
          list: this.permissionList.filter((item) => item.groupName === name),
        };
      });
    },
  },

  mounted() {
    const { id } = this.$route.query;

    fetchDetail({
      id,
    }).then((res) => {
      console.log("res", res);
      this.detail = res ?? {};
      if (Array.isArray(res?.permissions)) {
        this.selectedList = res.permissions.map((item) => item.id);
      }
    });

    fetchPermissionList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.permissionList = Object.freeze(res.list);
      }
    });
  },

  methods: {
    cancel() {
      this.$router.go(-1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.selectedKey = "2";
        }
      });
    },

    save() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.selectedList.length === 0) {
            this.$message.error("请选择权限");
            return;
          }

          edit({
            ...this.detail,
            ...values,
            permissions: this.selectedList,
          }).then(() => {
            this.cancel();
          });
        } else {
          this.$message.error("请填写数据");
          return;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.group-name {
  color: var(--theme-color);
  margin-bottom: 12px;
}
.permission-list {
  margin-bottom: 16px;
}
</style>
